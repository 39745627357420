import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { Link } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";

const Support = (props) =>{
  const [key, setKey] = useState(null)
  let invite_id = props.params.id;


return (
    <Layout>
        <div className="row align-items-center justify-content-center" style={{height:"650px"}}>
            <p className=""> Please Contact us <a href="mailto:arvind@joinearthly.com">arvind@joinearthly.com</a> </p>
        </div>
    </Layout>
)
}
export default Support;
